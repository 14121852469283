
import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
    HashRouter,
    useNavigate
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { auth } from './config';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'wc-toast'
import Login from './screens/Login';
import Signup from './screens/Signup';

import { Provider, connect } from "react-redux";
import store from "./screens/redux/stores/index";
import Dashboard from './screens/Dashboard';
import Vision from './screens/Vision';
import Goals from './screens/Goals';
import WeeklyPlan from './screens/WeeklyPlan';
import Journal from './screens/Journal';
import ActivityPlan from './screens/ActivityPlan';
import Wins from './screens/Wins';
import Habit from './screens/Habit';
import Videos from './screens/Videos';
import Dashboardcompany from './company_screens/Dashboardcompany';
import CompanyAccount from './company_screens/CompanyAccount';
import Payment from './company_screens/Payment';
import TeamMembers from './company_screens/TeamMembers';
import LoginCompany from './screens/LoginCompany';
import SignUpCompany from './screens/SignUpCompany';
import Forget from './screens/Forget';
import ForgetCompany from './screens/ForgetCompany';
import { IdleTimerComponent, useIdleTimer } from 'react-idle-timer'
import { LogOut } from './screens/redux/actions';
import Companies from './screens/Companies';
import Settings from './screens/Settings';
import StripePayment from './screens/StripePayment';
import StripePaymentCompany from './company_screens/StripePaymentCompany';
import Packages from './company_screens/Packages';
//import 'react-toastify/dist/ReactToastify.css';

const timeout = 1000*60*15
const promptBeforeIdle = 1000

 function App({items,LogOut}) {

    const ___doSingIn = async () => {
        try {
            let response = await auth.signInWithEmailAndPassword("n.joy@boomsoftware.co.uk", "2103199j");
            if (response && response.user) {

            }
        } catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        ___doSingIn()
    }, [])

    const [remaining, setRemaining] = useState(timeout)
   
    const onIdle = () => {
     console.log('Idle ')
    }
  
    const onActive = () => {
        console.log('Active ')
    }
  
    const onPrompt = () => {
      console.log('Promt ')
      if(items.logout==false){
        LogOut(true)
        toast('Your session has been expired . You are logged out.')
      }
    }
  
    const { getRemainingTime, activate } = useIdleTimer({
      onIdle,
      onActive,
      onPrompt,
      timeout,
      promptBeforeIdle,
      throttle: 500
    })
  
    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)
  
      return () => {
        clearInterval(interval)
      }
    })


   
    return (
        <div className="App">
                <HashRouter>
                    <Routes>

                        <Route path="/" element={<Login />} />
                        <Route path="/company_login" element={<LoginCompany />} />
                        <Route path="/company_signup" element={<SignUpCompany />} />


                        <Route path="/Signup" element={<Signup />} />
                        <Route path="/Dashboard" element={<Dashboard />} />
                        <Route path="/Vision" element={<Vision />} />
                        <Route path="/Goals" element={<Goals />} />
                        <Route path="/WeeklyPlan" element={<WeeklyPlan />} />
                        <Route path="/ActivityPlan" element={<ActivityPlan />} />
                        <Route path="/Journal" element={<Journal />} />
                        <Route path="/Wins" element={<Wins />} />
                        <Route path="/Habit" element={<Habit />} />
                        <Route path="/Videos" element={<Videos />} />
                        <Route path="/Dashboardcompany" element={<Dashboardcompany />} />
                        <Route path="/CompanyAccount" element={<CompanyAccount />} />
                        <Route path="/Payment" element={<Payment />} />

                        <Route path="/Forget" element={<Forget />} />
                        <Route path="/ForgetCompany" element={<ForgetCompany />} />

                        <Route path="/TeamMembers" element={<TeamMembers />} />
                        <Route path="/Companies" element={<Companies />} />
                        <Route path="/Settings" element={<Settings />} />
                        <Route path="/StripePayment" element={<StripePayment />} />
                        <Route path="/StripePaymentCompany" element={<StripePaymentCompany />} />
                        <Route path="/Packages" element={<Packages />} />


                    </Routes>
                </HashRouter>
            <wc-toast></wc-toast>
        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { LogOut })(App)